import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDatabase} from 'firebase/database';
import { getStorage} from 'firebase/storage';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDw-TIJ-JBjH1nO-YCHAMa3IAESXGmqa1E",
  authDomain: "zomagle-514ce.firebaseapp.com",
  projectId: "zomagle-514ce",
  storageBucket: "zomagle-514ce.appspot.com",
  messagingSenderId: "443455646399",
  appId: "1:443455646399:web:f66819f6fdde88f19a740a",
  measurementId: "G-XEPFKPCFXN",
  databaseURL: "https://zomagle-514ce-default-rtdb.firebaseio.com/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const storage = getStorage(app);

export { auth, db, storage };
