import React, { useState, useEffect } from 'react';
import { auth, db } from '../../services/firebase';
import { ref, onValue, push } from 'firebase/database';
import './ExplorePage.css';

import { countries, states, cities } from '../SignupPage/SignupPage'; // Adjust the path as necessary

const ExplorePage = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');

  useEffect(() => {
    // Simulate fetching the user's current city, state, country from their profile
    const userCountry = 'India'; // Replace with actual data fetch
    const userState = 'Delhi';
    const userCity = 'New Delhi';

    setSelectedCountry(userCountry);
    setSelectedState(userState);
    setSelectedCity(userCity);

    loadChatRoom(userCountry, userState, userCity);
  }, []);

  const loadChatRoom = (country, state, city) => {
    let chatRef;
    if (city) {
      chatRef = ref(db, `chats/${country}/${state}/${city}`);
      setWelcomeMessage(`Welcome to the ${city} room!`);
    } else if (state) {
      chatRef = ref(db, `chats/${country}/${state}`);
      setWelcomeMessage(`Welcome to the ${state} room!`);
    } else if (country) {
      chatRef = ref(db, `chats/${country}`);
      setWelcomeMessage(`Welcome to the ${country} room!`);
    }

    if (chatRef) {
      onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setMessages(Object.values(data));
        } else {
          setMessages([]);
        }
      });
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim()) {
      const chatRef = ref(db, `chats/${selectedCountry}/${selectedState}/${selectedCity}`);
      push(chatRef, {
        user: auth.currentUser.displayName,
        message: newMessage,
        timestamp: new Date().toISOString(),
      });
      setNewMessage('');
    }
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setSelectedState('');
    setSelectedCity('');
    loadChatRoom(country, '', '');
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity('');
    loadChatRoom(selectedCountry, state, '');
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
    loadChatRoom(selectedCountry, selectedState, city);
  };

  return (
    <div className="explore-container">
      <h1>Explore Chat Rooms</h1>
      <div className="location-selectors">
        <select
          value={selectedCountry}
          onChange={handleCountryChange}
        >
          <option value="">Select Country</option>
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>

        {selectedCountry && (
          <select
            value={selectedState}
            onChange={handleStateChange}
          >
            <option value="">Select State</option>
            {states[selectedCountry]?.map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        )}

        {selectedState && (
          <select
            value={selectedCity}
            onChange={handleCityChange}
          >
            <option value="">Select City</option>
            {cities[selectedState]?.map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        )}
      </div>

      {welcomeMessage && <div className="welcome-message">{welcomeMessage}</div>}

      <div className="chatroom">
        <div className="messages">
          {messages.map((msg, index) => (
            <div key={index} className="message">
              <strong>{msg.user}</strong>: {msg.message}
            </div>
          ))}
        </div>
        <div className="message-input">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type your message here..."
          />
          <button onClick={handleSendMessage}>Send</button>
        </div>
      </div>
    </div>
  );
};

export default ExplorePage;
