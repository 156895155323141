import React, { useState, useEffect, useRef } from 'react';
import { ref as dbRef, get, set } from 'firebase/database';
import { ref as storageRef, getDownloadURL, uploadBytes } from 'firebase/storage';
import { auth, db, storage } from '../../services/firebase';
import { updateProfile } from 'firebase/auth';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import './ProfilePage.css';
import { countries } from '../SignupPage/SignupPage.js';

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [profilePic, setProfilePic] = useState('');
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1 });
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [bio, setBio] = useState('');
  const [editMode, setEditMode] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = dbRef(db, 'users/' + user.uid);
      get(userRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserData(data);
            setProfilePic(data.profilePic || user.photoURL || 'https://your-fallback-url.com/default_profile.png');
            setBio(data.bio || '');
          }
        })
        .catch((error) => console.error('Error fetching user data:', error));
    }
  }, []);

  const handleProfilePicChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImageFile(file);
      const reader = new FileReader();
      reader.onload = () => setNewProfilePic(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (crop) => {
    if (imgRef.current && crop.width && crop.height) {
      createCroppedImage(crop);
    }
  };

  const createCroppedImage = (crop) => {
    if (!crop.width || !crop.height) return;

    const canvas = document.createElement('canvas');
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob((blob) => {
      if (blob) {
        setCroppedImageUrl(URL.createObjectURL(blob));
      }
    }, imageFile?.type || 'image/jpeg');
  };

  const handleProfilePicUpload = async () => {
    if (!croppedImageUrl || !auth.currentUser) return;

    try {
      const user = auth.currentUser;
      const storageReference = storageRef(storage, `profile_pics/${user.uid}`);
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      await uploadBytes(storageReference, blob);
      const downloadURL = await getDownloadURL(storageReference);

      await updateProfile(user, { photoURL: downloadURL });

      const userRef = dbRef(db, 'users/' + user.uid);
      await set(userRef, { ...userData, profilePic: downloadURL });

      setProfilePic(downloadURL);
      setNewProfilePic(null);
      setImageFile(null);
      setCroppedImageUrl('');
    } catch (error) {
      console.error('Error uploading profile picture:', error);
      alert('Error uploading profile picture. Please try again.');
    }
  };

  const handleCancel = () => {
    setNewProfilePic(null);
    setImageFile(null);
    setCroppedImageUrl('');
  };

  const handleLogout = () => {
    auth.signOut().then(() => {
      sessionStorage.clear();
      window.location.href = '/';
    }).catch(error => {
      console.error('Error signing out:', error);
      alert('Error signing out. Please try again.');
    });
  };

  const handleBioChangeInput = (e) => {
    setBio(e.target.value);
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleSaveChanges = async () => {
    if (bio.length > 300) {
      alert('Bio cannot be more than 300 characters.');
      return;
    }

    try {
      const user = auth.currentUser;
      const userRef = dbRef(db, 'users/' + user.uid);
      await set(userRef, {
        ...userData,
        profilePic: profilePic,
        bio: bio,
        firstName: userData?.firstName || '',
        lastName: userData?.lastName || '',
        country: userData?.country || '',
        state: userData?.state || '',
        city: userData?.city || '',
      });

      setUserData((prevData) => ({
        ...prevData,
        bio: bio,
        profilePic: profilePic,
      }));
      setEditMode(false);
    } catch (error) {
      console.error('Error saving profile changes:', error);
      alert('Error saving changes. Please try again.');
    }
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setNewProfilePic(null);
    setImageFile(null);
    setCroppedImageUrl('');
    setBio(userData?.bio || '');
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setUserData(prevData => ({ ...prevData, country: selectedCountry, state: '', city: '' }));
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setUserData(prevData => ({ ...prevData, state: selectedState, city: '' }));
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setUserData(prevData => ({ ...prevData, city: selectedCity }));
  };

  const getStates = () => {
    // Implement a function to return states based on the selected country
    return [];
  };

  const getCities = () => {
    // Implement a function to return cities based on the selected state
    return [];
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-page">
      <div className="profile-header">
        <h1>Profile</h1>
        <button onClick={handleEditClick} className="edit-btn">Edit Profile</button>
      </div>
      <div className="profile-content">
        <div className="profile-picture">
          {editMode ? (
            <div className="crop-container">
              {newProfilePic && (
                <>
                  <ReactCrop
                    src={newProfilePic}
                    crop={crop}
                    onImageLoaded={(img) => (imgRef.current = img)}
                    onChange={(newCrop) => setCrop(newCrop)}
                    onComplete={handleCropComplete}
                    className="image-preview"
                  />
                  <button className="action-btn" onClick={handleProfilePicUpload}>Apply</button>
                  <button className="action-btn" onClick={handleCancel}>Cancel</button>
                </>
              )}
              <input type="file" accept="image/*" onChange={handleProfilePicChange} />
            </div>
          ) : (
            <img src={profilePic} alt="Profile" className="profile-pic" />
          )}
        </div>
        {editMode ? (
          <div className="profile-edit-form">
            <label className="profile-label">First Name:</label>
            <input
              type="text"
              value={userData.firstName || ''}
              onChange={(e) => setUserData(prevData => ({ ...prevData, firstName: e.target.value }))}
              className="profile-input"
            />

            <label className="profile-label">Last Name:</label>
            <input
              type="text"
              value={userData.lastName || ''}
              onChange={(e) => setUserData(prevData => ({ ...prevData, lastName: e.target.value }))}
              className="profile-input"
            />

            <label className="profile-label">Country:</label>
            <select
              value={userData.country || ''}
              onChange={handleCountryChange}
              className="profile-input"
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.name} value={country.name}>{country.name}</option>
              ))}
            </select>

            <label className="profile-label">State:</label>
            <select
              value={userData.state || ''}
              onChange={handleStateChange}
              className="profile-input"
              disabled={!userData.country}
            >
              <option value="">Select State</option>
              {getStates().map((state) => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>

            <label className="profile-label">City:</label>
            <select
              value={userData.city || ''}
              onChange={handleCityChange}
              className="profile-input"
              disabled={!userData.state}
            >
              <option value="">Select City</option>
              {getCities().map((city) => (
                <option key={city} value={city}>{city}</option>
              ))}
            </select>

            <label className="profile-label">Bio:</label>
            <textarea
              value={bio}
              onChange={handleBioChangeInput}
              maxLength="300"
              className="profile-input"
            />

            <button onClick={handleSaveChanges} className="action-btn">Save Changes</button>
            <button onClick={handleCancelEdit} className="action-btn">Cancel</button>
          </div>
        ) : (
          <div className="profile-details">
            <p><strong>First Name:</strong> {userData.firstName}</p>
            <p><strong>Last Name:</strong> {userData.lastName}</p>
            <p><strong>Country:</strong> {userData.country}</p>
            <p><strong>State:</strong> {userData.state}</p>
            <p><strong>City:</strong> {userData.city}</p>
            <p><strong>Bio:</strong> {userData.bio}</p>
          </div>
        )}
      </div>
      <button onClick={handleLogout} className="logout-btn">Logout</button>
    </div>
  );
};

export default ProfilePage;
