import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../services/firebase';
import './LandingPage.css';
import logo from './logo.png'; // Adjust the path based on the actual location of logo.png

function LandingPage({ setIsLoggedIn }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    // Retrieve message from sessionStorage
    const message = sessionStorage.getItem('signupMessage');
    if (message) {
      setError(message);
      // Clear the message after displaying it
      sessionStorage.removeItem('signupMessage');
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        sessionStorage.setItem('loggedIn', 'true');
        setIsLoggedIn(true);
        navigate('/explore');
      } else {
        setError('Please verify your email before logging in.');
      }
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setError('Incorrect password. Please try again.');
      } else if (error.code === 'auth/user-not-found') {
        setError('No account found with this email. Please sign up first.');
      } else {
        setError('Failed to log in. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSignUpRedirect = () => {
    navigate('/register');
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    if (error) setError('');
  };

  return (
    <div className="landing-container">
      <div className="left-section">
        <img src={logo} alt="Zomagle Logo" className="logo" />
        <p>Helps you connect and share with the people in your life.</p>
      </div>
      <div className="right-section">
        {error && <p className="error-message">{error}</p>}
        <form className="login-form" onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email address or phone number"
            value={email}
            onChange={handleInputChange(setEmail)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={handleInputChange(setPassword)}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Logging in...' : 'Log in'}
          </button>
        </form>
        <div className="extra-options">
          <a href="/forgot-password">Forgotten password?</a>
          <button className="create-account-button" onClick={handleSignUpRedirect} disabled={loading}>
            Create new account
          </button>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
