// src/components/Connections/ConnectionsPage.js
import React from 'react';

const ConnectionsPage = () => {
  return (
    <div>
      <h1>Connections</h1>
      <p>Manage your connections here.</p>
    </div>
  );
};

export default ConnectionsPage;
