import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage } from '../../services/firebase';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, deleteUser } from 'firebase/auth';
import { ref, set, get } from 'firebase/database';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import './SignupPage.css';

export const countries = ['India', 'Pakistan', 'Russia', 'Australia'];
export const states = {
  'India': ['Delhi', 'Maharashtra', 'Karnataka', 'West Bengal', 'Tamil Nadu'],
  'Pakistan': ['Punjab', 'Sindh', 'Khyber Pakhtunkhwa', 'Balochistan'],
  'Russia': ['Moscow', 'Saint Petersburg', 'Novosibirsk', 'Yekaterinburg', 'Nizhny Novgorod'],
  'Australia': ['New South Wales', 'Victoria', 'Queensland', 'Western Australia', 'South Australia']
};
export const cities = {
  'Delhi': ['New Delhi', 'Old Delhi', 'Rohini', 'Dwarka', 'Vasant Kunj'],
  'Maharashtra': ['Mumbai', 'Pune', 'Nagpur', 'Nashik', 'Aurangabad'],
  'Karnataka': ['Bengaluru', 'Mysuru', 'Hubballi', 'Davanagere', 'Belagavi'],
  'West Bengal': ['Kolkata', 'Siliguri', 'Durgapur', 'Asansol', 'Howrah'],
  'Tamil Nadu': ['Chennai', 'Coimbatore', 'Madurai', 'Salem', 'Tiruchirappalli'],
  'Punjab': ['Lahore', 'Faisalabad', 'Rawalpindi', 'Multan', 'Gujranwala'],
  'Sindh': ['Karachi', 'Hyderabad', 'Sukkur', 'Larkana', 'Mirpurkhas'],
  'Khyber Pakhtunkhwa': ['Peshawar', 'Mardan', 'Abbottabad', 'Swat', 'Kohat'],
  'Balochistan': ['Quetta', 'Gwadar', 'Turbat', 'Khuzdar', 'Zhob'],
  'Moscow': ['Moscow City'],
  'Saint Petersburg': ['Saint Petersburg City'],
  'Novosibirsk': ['Novosibirsk City'],
  'Yekaterinburg': ['Yekaterinburg City'],
  'Nizhny Novgorod': ['Nizhny Novgorod City'],
  'New South Wales': ['Sydney', 'Newcastle', 'Wollongong', 'Central Coast', 'Coffs Harbour'],
  'Victoria': ['Melbourne', 'Geelong', 'Ballarat', 'Bendigo', 'Shepparton'],
  'Queensland': ['Brisbane', 'Gold Coast', 'Townsville', 'Cairns', 'Toowoomba'],
  'Western Australia': ['Perth', 'Mandurah', 'Bunbury', 'Kalgoorlie', 'Geraldton'],
  'South Australia': ['Adelaide', 'Mount Gambier', 'Gawler', 'Whyalla', 'Port Pirie']
};

function SignupPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateForm = () => {
    if (!firstName || !lastName || !email || !dob || !gender || !password || !confirmPassword || !city || !state || !country || !username) {
      return 'All fields are required.';
    }
    if (password !== confirmPassword) {
      return 'Passwords do not match.';
    }
    if (!/^[a-z0-9]+$/.test(username)) {
      return 'Username can only contain lowercase letters and numbers.';
    }
    return '';
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      setLoading(false);
      return;
    }
  
    try {
      const usernameTaken = await checkUsernameExists(username);
      if (usernameTaken) {
        setError('Username is already taken.');
        setLoading(false);
        return;
      }
  
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Default profile picture URL handling
      let defaultProfilePicUrl = '';
      try {
        const storageReference = storageRef(storage, 'default_profile.png');
        defaultProfilePicUrl = await getDownloadURL(storageReference);
      } catch (storageError) {
        console.error('Error fetching default profile picture:', storageError);
        defaultProfilePicUrl = ''; // Fallback if fetching fails
      }
  
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
        photoURL: defaultProfilePicUrl,
      });
  
      await sendEmailVerification(user);
  
      const userData = {
        firstName,
        lastName,
        username,
        email,
        dob,
        gender,
        city,
        state,
        country,
        profilePic: defaultProfilePicUrl,
        bio: "I am an explorer",
      };
  
      // Store user data in 'users' node
      await set(ref(db, 'users/' + user.uid), userData);
  
      // Store username to ensure uniqueness in 'usernames' node
      await set(ref(db, `usernames/${username}`), user.uid);
  
      sessionStorage.setItem('signupMessage', 'Signup successful! Please verify your email before logging in.');
      navigate('/');
    } catch (error) {
      console.error('Sign-up error:', error);
      if (error.code === 'auth/email-already-in-use') {
        setError('This email is already in use. Please use a different email.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Invalid email address. Please enter a valid email.');
      } else if (error.code === 'auth/weak-password') {
        setError('Password is too weak. Please choose a stronger password.');
      } else {
        setError('Failed to sign up. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };
  const checkUsernameExists = async (username) => {
    try {
      const usernameRef = ref(db, `usernames/${username}`);
      const snapshot = await get(usernameRef);
      return snapshot.exists();  // Check if username exists
    } catch (error) {
      console.error('Error checking username existence:', error.message);
      return false; // Default to false if there's an error
    }
  };
  

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    if (error) setError('');
  };

  const handleCountryChange = (e) => {
    const selectedCountry = e.target.value;
    setCountry(selectedCountry);
    setState('');
    setCity('');
  };

  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setState(selectedState);
    setCity('');
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSignup}>
        <h2>Create New Account</h2>

        <input
          type="text"
          placeholder="First Name"
          value={firstName}
          onChange={handleInputChange(setFirstName)}
          required
        />

        <input
          type="text"
          placeholder="Last Name"
          value={lastName}
          onChange={handleInputChange(setLastName)}
          required
        />

        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={handleInputChange(setUsername)}
          required
        />

        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleInputChange(setEmail)}
          required
        />

        <input
          type="date"
          placeholder="Date of Birth"
          value={dob}
          onChange={handleInputChange(setDob)}
          required
        />

        <div className="gender-selection">
          <label>
            <input
              type="radio"
              value="male"
              checked={gender === 'male'}
              onChange={handleInputChange(setGender)}
              required
            />
            Male
          </label>
          <label>
            <input
              type="radio"
              value="female"
              checked={gender === 'female'}
              onChange={handleInputChange(setGender)}
              required
            />
            Female
          </label>
          <label>
            <input
              type="radio"
              value="other"
              checked={gender === 'other'}
              onChange={handleInputChange(setGender)}
              required
            />
            Other
          </label>
        </div>

        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handleInputChange(setPassword)}
          required
        />

        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={handleInputChange(setConfirmPassword)}
          required
        />

        <select value={country} onChange={handleCountryChange} required>
          <option value="">Select Country</option>
          {countries.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </select>

        <select value={state} onChange={handleStateChange} disabled={!country} required>
          <option value="">Select State</option>
          {states[country]?.map((s) => (
            <option key={s} value={s}>
              {s}
            </option>
          ))}
        </select>

        <select value={city} onChange={(e) => setCity(e.target.value)} disabled={!state} required>
          <option value="">Select City</option>
          {cities[state]?.map((c) => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </select>

        <button type="submit" disabled={loading}>
          {loading ? 'Signing up...' : 'Sign Up'}
        </button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
}

export default SignupPage;
