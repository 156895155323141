import React from 'react';

const MessagesPage = () => {
  return (
    <div>
      <h1>Direct Messages</h1>
      <p>Here you can view and send direct messages.</p>
    </div>
  );
};

export default MessagesPage;
