import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import logo from '../Navbar/logo.png'; // Make sure the path is correct

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <img src={logo} alt="Zomagle Logo" className="logo" />
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
        <li>
          <NavLink 
            to="/explore" 
            className={({ isActive }) => isActive ? 'active' : ''}>
            Explore
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/connections" 
            className={({ isActive }) => isActive ? 'active' : ''}>
            Connections
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/messages" 
            className={({ isActive }) => isActive ? 'active' : ''}>
            Messages
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/profile" 
            className={({ isActive }) => isActive ? 'active' : ''}>
            Profile
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
