// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from './components/LandingPage/LandingPage';
import SignupPage from './components/SignupPage/SignupPage';
import ExplorePage from './components/Explore/ExplorePage'; // Ensure this import is correct
import ProfilePage from './components/Profile/ProfilePage';
import MessagesPage from './components/MessagesPage/MessagesPage';
import ConnectionsPage from './components/Connections/ConnectionsPage';
import Navbar from './components/Navbar/Navbar';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check login state from sessionStorage
    const loggedIn = sessionStorage.getItem('loggedIn') === 'true';
    setIsLoggedIn(loggedIn);
  }, []);

  // Force re-render when isLoggedIn state changes
  useEffect(() => {
    if (isLoggedIn) {
      // Trigger a forced re-render
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  return (
    <Router>
      <Routes>
        {!isLoggedIn ? (
          <>
            <Route path="/" element={<LandingPage setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/register" element={<SignupPage />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Navigate to="/explore" />} />
            <Route path="/explore" element={<><Navbar /><ExplorePage /></>} />
            <Route path="/profile" element={<><Navbar /><ProfilePage /></>} />
            <Route path="/messages" element={<><Navbar /><MessagesPage /></>} />
            <Route path="/connections" element={<><Navbar /><ConnectionsPage /></>} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
